<template>
  <div class="caseinfo">
     <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="cotainer">
      <div class="header">
        <h1>高铁地铁项目精装修</h1>
        <p>时间：2019-2.21</p>
      </div>
        <div class="introduce mt_30">
            <p>建模软件：Revit,Tekla,Rhino,3DMax</p>
            <p>地形数据：倾斜摄影</p>
            <p>模型文件：11.9 GB</p>
            <p>构件数量：54.68万个</p>
            <p>面片数量：5.46亿</p>
        </div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(2)_gtdt.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(2)_gtdt2.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(2)_gtdt3.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(2)_gtdt4.png" alt=""></div>
    </div>
</div>
</template>

<script>
export default {
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>


<style lang="less" scoped>
.mt_30{
  margin-top: 30px;
}
.caseinfo{
  width: 100%;
  // height: 100%;
  .cotainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    line-height: 30px;
    h1{
      font-weight: normal;
    }
    h2{
      line-height: 50px;
    }
    .header{
      p{
        color: rgba(51,51,51,1);
        opacity: 0.7;
      }
    }
  }
}
</style>
